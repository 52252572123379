import api from "@/service/api/Search";

const state = {
	orderBy: {
		name: "distance",
		order: "asc",
	},

	search: false,
	moreSearch: false,

	searchData: {
		method: "system", // system || google
		source: "all", // all || favorite
		searchRange: false,
		query: {
			eleType: "all",
			coordinate: {
				lat: "",
				lng: "",
			},
			range: 20,
			favorite: "",
			country: "",
			city: "",
			class: [],
		},
		pageNumber: {
			page: 0,
			number: 50,
		},
		orderBy: {
			name: "distance",
			order: "asc",
		},
	},

	searchDataTemp: {},

	searchTemp: {
		count: 0,
		data: [],
	},
};

const getters = {};

const actions = {
	async getPoint({ commit }, data) {
		const result = await api.getPoint(data);
		return result;
	},

	async getPointProduct({ commit }, data) {
		const result = await api.getPointProduct(data);
		return result;
	},

	async getPointImage({ commit }, data) {
		const result = await api.getPointImage(data);
		return result;
	},

	async searchPoint({ state, commit, dispatch, rootState }, data) {
		commit("Tour/setElement", false, {
			root: true,
		});

		commit("setSearchDataTemp", data);
		state.searchDataTemp.query.coordinate = state.searchDataTemp.query.coordinate || {};
		state.searchDataTemp.query.coordinate.lat = state.searchDataTemp.query.coordinate.lat || rootState.Map.mapOption.center[0];
		state.searchDataTemp.query.coordinate.lng = state.searchDataTemp.query.coordinate.lng || rootState.Map.mapOption.center[1];

		const method = state.searchDataTemp.method || "stsyem";
		const search = {
			source: state.searchDataTemp.source,
			searchRange: state.searchDataTemp.searchRange,
			query: state.searchDataTemp.query,
			pageNumber: state.searchDataTemp.pageNumber,
			orderBy: state.searchDataTemp.orderBy,
		};

		const result = await api.searchPoint(method, search);
		// return result;
		if (result.status) {
			commit(
				"Map/setSearchFoucsTemp",
				{},
				{
					root: true,
				}
			);
			commit("setSearchTemp", result.result);

			commit("Map/setSearchShow", true, {
				root: true,
			});

			commit("Map/setSearchHidden", true, {
				root: true,
			});
		}

		return result;
	},

	async searchMore({ state, commit }, data) {
		commit("Tour/setElement", false, {
			root: true,
		});

		const method = state.searchDataTemp.method || "stsyem";
		const search = {
			source: state.searchDataTemp.source,
			searchRange: state.searchDataTemp.searchRange,
			query: state.searchDataTemp.query,
			pageNumber: state.searchDataTemp.pageNumber,
			orderBy: state.searchDataTemp.orderBy,
		};

		const result = await api.searchPoint(method, search);

		if (result.status) {
			commit("pushSearchTemp", result.result);

			commit("Map/setSearchShow", true, {
				root: true,
			});

			commit("Map/setSearchHidden", true, {
				root: true,
			});
		}

		return result;
	},
};

const mutations = {
	setOrderByName(state, data) {
		state.orderBy.name = data;
	},

	setOrderByOrder(state) {
		state.orderBy.order = state.orderBy.order == "asc" ? "desc" : "asc";
	},

	setSearch(state, data) {
		state.search = data;
	},

	setMoreSearch(state, data) {
		state.moreSearch = data;
	},

	setSearchCoordinate(state, coordinate) {
		state.searchData.query.coordinate.lat = coordinate.lat;
		state.searchData.query.coordinate.lng = coordinate.lng;
	},

	setSearchDataClass(state, data) {
		_.each(data, (item, key) => {
			if (item.searchRule) {
				const index = _.indexOf(state.searchData.query.class, item.values);
				if (index > -1) {
					return;
				}

				state.searchData.query.class.push(item.values);
			}
		});
	},

	setSearchDataTemp(state, data) {
		state.searchDataTemp = data;
	},

	setSearchTemp(state, data) {
		state.searchTemp.count = data.count;
		state.searchTemp.data = _.map(data.data, (item, key) => {
			item.distance = Math.round(item.distance * 100) / 100;
			return item;
		});

		this.commit("Search/setNextPageNumber", data.next_pageNumber);
	},

	pushSearchTemp(state, data) {
		state.searchTemp.data = _.concat(
			state.searchTemp.data,
			_.map(data.data, (item, key) => {
				item.distance = Math.round(item.distance * 100) / 100;
				return item;
			})
		);

		this.commit("Search/setNextPageNumber", data.next_pageNumber);
	},

	setNextPageNumber(state, data) {
		state.searchDataTemp.pageNumber = data;
	},

	setRecommend(state, data) {
		let temp = _.find(state.searchTemp.data, (item, key) => {
			return item.uid == data.uid;
		});

		if (temp) {
			temp.recommend = data.recommend;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
