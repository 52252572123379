<template>
	<div id="id">
		<div class="m-edit-input d-flex align-items-center px-4" :class="{ error: error.use && !error.status }">
			<div class="mr-2 fs-4" :class="{ 'text-orange': error.use && !error.status, 'text-main': !error.use || error.status }" v-if="icon.show">
				<i :class="icon.icon"></i>
			</div>

			<div class="flex-fill d-flex align-items-center position-relative">
				<div class="flex-fill form-floating pr-4">
                    <label class="m-small-1 text-muted m-0">{{ title }}<span class="text-danger ml-1" v-if="cantNull">*</span></label>
					<input :type="getType" class="form-control m-input px-0" :placeholder="title" v-model="getValues" @change="changeInput" @blur="blurInput" />
				</div>

				<span class="position-absolute text-orange" style="right: 0.3rem;" v-if="error.use && !error.status">
					<i class="bi bi-exclamation-circle"></i>
				</span>
			</div>

			<div class="d-flex align-items-center justify-content-center fs-4" @click="data.hidePassword = !data.hidePassword" v-if="type == 'password'">
				<span v-show="!data.hidePassword">
					<i class="bi bi-eye"></i>
				</span>

				<span v-show="data.hidePassword">
					<i class="bi bi-eye-slash"></i>
				</span>
			</div>
		</div>

		<slot name="errorBox" v-if="error.use">
			<div class="d-flex align-items-center px-4" style="min-height: 2rem;">
				<span class="text-fill text-orange small" v-if="!error.status">{{ error.msg || "錯誤回報" }}</span>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	data() {
		const random = Math.random() * 9999999999;
		const id = "editInput_" + random;
		const data = {
			hidePassword: true,
		};

		return { id: id, data: data };
	},

	props: {
		cantNull: {
			type: Boolean,
			default: false,
		},
		model: {
			type: [String, Number],
		},
		type: {
			type: String,
			default: "text",
		},
		icon: {
			type: Object,
			default: () => {
				return { show: false, icon: "" };
			},
		},
		title: {
			type: String,
		},
		error: {
			type: Object,
			default: () => {
				return {
					use: false,
					status: true,
					msg: "錯誤回報",
				};
			},
		},
	},

	computed: {
		getType() {
			if (this.type == "password") {
				return data.hidePassword ? this.type : "text";
			}

			return this.type;
		},

		getValues: {
			get() {
				return this.model;
			},
			set(values) {
				this.$emit("update:model", values);
			},
		},
	},

	methods: {
		changeInput() {
			this.$emit("changeFunc", { values: this.model });
		},

		blurInput() {
			this.$emit("blurFunc");
		},
	},
};
</script>
