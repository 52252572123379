import App from "./App.vue";
import Vue from "vue";
import axios from "axios";
import router from "./router";
import store from "./store";

import { sync } from "vuex-router-sync";

import "./plugins/fontawesome";
import "./registerServiceWorker";

// ---------- directive ----------
import "./directive/styleelement.js";
import "./directive/Public.js";

// ---------- filter ----------
import "./service/filter/publicfilter.js";
import VueCurrencyFilter from "vue-currency-filter";

// ---------- filter ----------
import moment from "moment";
import VueMoment from "vue-moment";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import VueCarousel from "vue-carousel";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import GanttElastic from "gantt-elastic";
import Password from "vue-password-strength-meter";
import fileUpload from "vue-upload-component";

// ---------- CSS ----------
import "vue-select/dist/vue-select.css";
import "vue-loading-overlay/dist/vue-loading.css";
// import "./style/variables.scss";
import "./style/_bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./style/user.scss";

import { BootstrapVue, BIcon, BootstrapVueIcons } from "bootstrap-vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

// ---------- custom ----------
import EditText from "@/components/Input/EditText";
import EditTextArea from "@/components/Input/EditTextArea";
import EditSelect from "@/components/Input/EditSelect";
import Edit from "@/components/Input/Edit";

vSelect.props.components = {
	default: () => ({
		OpenIndicator: {
			render: (createElement) => createElement("span", "🔽"),
		},
	}),
};
Vue.component("BIcon", BIcon);
Vue.component("v-select", vSelect);
Vue.component("Password", Password);
Vue.component("GanttElastic", GanttElastic);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component("file-upload", fileUpload);
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);
Vue.component("EditText", EditText);
Vue.component("EditTextArea", EditTextArea);
Vue.component("EditSelect", EditSelect);
Vue.component("Edit", Edit);

Vue.config.productionTip = false;

// const gauthOption = {
//   clientId: process.env.VUE_APP_GOOGLE_KEY,
//   scope: 'profile email',
//   prompt: 'select_account'
// }

// Vue.use(VueCropper);
// Vue.use(GAuth, gauthOption);
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMoment, {
	moment,
});
Vue.use(DatePicker);
Vue.use(VueCurrencyFilter, {
	symbol: "$",
	thousandsSeparator: ",",
	fractionCount: 0,
	fractionSeparator: ",",
	symbolPosition: "front",
	symbolSpacing: true,
});

Vue.moment().locale();
function searchToObject() {
	var pairs = window.location.search.substring(1).split("&"),
		obj = {},
		pair,
		i;

	for (i in pairs) {
		if (pairs[i] === "") continue;

		pair = pairs[i].split("=");
		obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
	}

	return obj;
}
const query = searchToObject();
localStorage.setItem("Authorization", query.token || localStorage.Authorization || "");
axios.defaults.headers.common["Authorization"] = "Bearer " + (query.token || localStorage.Authorization || "");
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";

moment.locale("zh-tw");
// store.dispatch("Public/lang", "zh-tw");

sync(store, router);

Vue.config.productionTip = false;

// app.all('*', (req, res, next) => {
//   agent = req.headers['user-agent']
//   if agent.indexOf('Safari') > -1 and agent.indexOf('Chrome') is - 1 and agent.indexOf(
//     'OPR') is - 1
//   res.header 'Cache-Control', 'no-cache, no-store, must-revalidate'
//   res.header 'Pragma', 'no-cache'
//   res.header 'Expires', 0
//   next()
// })

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
