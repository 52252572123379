import Vue from "vue";
import store from "@/store";
import Router from "vue-router";

Vue.use(Router);

const getPage = (name, path = "views") => () => {
	return import(`./${path}/${name}.vue`);
};

const router = new Router({
	mode: process.env.VUE_APP_MODEL,
	base: process.env.VUE_APP_URL,
	fallback: true,
	routes: [
		{
			path: "/",
			component: getPage("Index"),
			children: [
				{
					name: "preview",
					path: "/preview/:uid",
					meta: {
						title: "旅遊百匯-瀏覽",
						header: true,
					},
					components: {
						header: getPage("Head"),
						context: getPage("Content/Preview"),
					},
				},
				{
					name: "edit",
					path: "/edit/:uid",
					meta: {
						title: "旅遊百匯-修改",
						header: true,
					},
					components: {
						header: getPage("Head"),
						context: getPage("Edit"),
					},
				},
				{
					name: "quote",
					path: "/quote/:uid",
					meta: {
						title: "旅遊百匯-估價",
						header: true,
					},
					components: {
						header: getPage("Head"),
						context: getPage("Content/Quote"),
					},
				},
			],
		},
		{
			name: "create",
			path: "/create",
			meta: {
				title: "旅遊百匯-新增",
				header: true,
			},
			component: getPage("Create"),
		},
		{
			name: "exit",
			path: "/exit",
			meta: {
				title: "旅遊百匯-離開",
				header: true,
			},
			component: getPage("Exit"),
		},
	],
});

router.beforeEach(async (to, from, next) => {
	// 變更 website title
	document.title = to.meta && to.meta.title ? to.meta.title : "旅遊百匯";

	// 轉頁
	if (to.name == "exit") {
		next();
		return;
	}

	// const token = to.query.token || "";
	const checkUser = await store.dispatch("Member/checkUser");

	if (store.state.Member.Login.status) {
		next();
	} else {
		next({
			name: "exit",
		});
	}
});

router.afterEach(async (to, from, next) => {
	$("body, html").scrollTop(0, 500);
});

export default router;
