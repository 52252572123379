import api from "@/service/api/Map";

const state = {
  mapShow: true,
  mapOption: {
    url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png", // 地圖
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors', // 地圖提供

    zoom: 15,
    center: [25.0475613, 121.5173399], // 地圖中心
    myCenter: [25.0475613, 121.5173399], // 我的座標
    nowCenter: [25.0475613, 121.5173399],

    // ----- offset -----
    markerLatLng: [51.504, -0.159],
  },

  dayShow: [],
  strokeTemp: [],

  searchShow: false,
  searchHidden: false,

  dayColor: ["#0F85B1", "#C5C2F2", "#1BC3EA", "#8A1BEA", "#8CC084", "#E2BA4B", "#EF6654"],
  searchFoucsTemp: {},
  strokeFoucsTemp: {}
}

const getters = {
  checkDayShow(state) {
    return (dayIndex) => {
      return state.dayShow.indexOf(dayIndex) > -1;
    }
  },

  getDayTemp(state, getters, rootState) {
    // const strokeTemp = _.find(state.strokeTemp, {
    //   uid: rootState.Tour.productTemp.tourGroupProduct.sourceStroke
    // });

    const strokeTemp = state.strokeTemp;

    if (strokeTemp) {
      return strokeTemp.dayTemp
    }

    return [];
  },

  getMapTemp(state, getters, rootState) {
    // const strokeTemp = _.find(state.strokeTemp, {
    //   uid: rootState.Tour.productTemp.tourGroupProduct.sourceStroke
    // });

    const strokeTemp = state.strokeTemp;

    if (strokeTemp) {
      const day = strokeTemp.dayTemp;
      const result = [];

      _.each(day, (item, key) => {
        _.each(item.strokeTemp, (items, keys) => {
          result.push({
            dayIndex: key,
            index: keys,
            dayUid: item.uid,
            uid: items.uid,
            lat: items.lat,
            lng: items.lng,
            type: items.dataType,
            name: items.name || items.sourceName,
            ename: items.ename,
            images: items.images,
            price: items.tprice,
            cost: items.tcost,
            currency: items.currency,
            iscancel: items.iscancel,
            mapShow: items.mapShow,
            strokeShow: items.strokeShow,
            appraiseShow: items.appraiseShow,
          })
        });
      });

      return result;
    }

    return []
  },

  getSearchTemp(state, getters, rootState) {
    const searchTemp = rootState.Search.searchTemp.data;

    if (searchTemp) {
      return searchTemp;
    }

    return [];
  }
}

const actions = {

  async getCoordinate({
    commit
  }) {
    const result = await navigator.geolocation;
    result.getCurrentPosition((data) => {
      const coordinate = [data.coords.latitude, data.coords.longitude];
      commit("setCenter", coordinate);
      commit("setMyCenter", coordinate);
      commit("setNowCenter", coordinate);
      commit("Search/setSearchCoordinate", {
        lat: coordinate[0],
        lng: coordinate[1]
      }, {
        root: true
      });
    }, (error) => {
      commit("Public/addAlert", {
        color: "warning",
        title: "系統提示",
        msg: "您的裝置不支援定位功能",
      }, {
        root: true
      });
    });
  }
}

const mutations = {
  setSearchFoucsTemp(state, data) {
    state.searchFoucsTemp = data;
  },

  setStrokeFoucsTemp(state, data) {
    state.strokeFoucsTemp = data
  },

  setDayShow(state, dayIndex) {
    this.commit("Tour/setFocus", dayIndex + 1);
    const index = state.dayShow.indexOf(dayIndex);

    if (index > -1) {
      state.dayShow.splice(index, 1)
    } else {
      state.dayShow.push(dayIndex)
    };

    console.log(index, state.dayShow);
  },

  setStrokeTemp(state, data) {
    state.strokeTemp = _.first(data);

    // if (state.strokeTemp) {
    //   _.each(state.strokeTemp.dayTemp, (item, key) => {
    //     const index = state.dayShow.indexOf(key);
    //     index > -1 ? null : state.dayShow.push(key);
    //   });
    // }
  },

  setFocusDay(state, day) {
    const index = _.indexOf(state.dayShow, day);
    if (index == -1) {
      state.dayShow.push(day);
    }

    if (state.strokeTemp.dayTemp) {
      const stroke = _.first(state.strokeTemp.dayTemp[day].strokeTemp);
      if (stroke) {
        this.commit("Map/setCenter", [stroke.lat, stroke.lng]);
      }
    }
  },

  setCenter(state, coordinate) {
    state.mapOption.center = coordinate;
  },

  setMyCenter(state, coordinate) {
    state.mapOption.myCenter = coordinate;
  },

  setNowCenter(state, coordinate) {
    state.mapOption.nowCenter = coordinate;
  },

  setZoom(state, zoom) {
    state.mapOption.zoom = zoom;
  },

  setShowMap(state, data) {
    state.mapShow = data;
  },

  setSearchShow(state, data) {
    state.searchShow = data;
  },

  setSearchHidden(state, data) {
    state.searchHidden = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
