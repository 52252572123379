import http from "../support";
export default {
  getElementWithImage(uid) {
    return http.get("Schedule/getElementWithImage/" + uid);
  },

  createProduct(data) {
    return http.post("Schedule/createProduct", data);
  },

  getProduct(uid) {
    return http.get("Schedule/getProduct/" + uid);
  },

  getStroke(uid) {
    return http.get("Schedule/getStroke/" + uid);
  },

  saveDay(data) {
    return http.post("Schedule/saveDay", data);
  },

  saveStroke(data) {
    return http.post("Schedule/saveStroke", data);
  },

  editStroke(data) {
    return http.post("Schedule/editStroke", data);
  },

  saveStopTime(data) {
    return http.post("Schedule/saveStopTime", data);
  },

  saveMoveTime(data) {
    return http.post("Schedule/saveMoveTime", data);
  },

  saveOrder(data) {
    return http.post("Schedule/saveOrder", data);
  },

  getElement(uid) {
    return http.get("Schedule/getElement/" + uid);
  },

  pointRecommend(uid) {
    return http.get("Schedule/pointRecommend/" + uid);
  },

  addFavorite(data) {
    return http.post("Schedule/addFavorite", data);
  },

  batchEdit(data) {
    return http.post("Schedule/batchEdit" + `/${data.method}/${data.strokeUid}`, data.data);
  },

  createPoint(data) {
    return http.post("Schedule/createPoint", data);
  },

  editPoint(data) {
    return http.post("Schedule/editPoint", data);
  },

  suggestPoint(data) {
    return http.post("Schedule/suggestPoint", data);
  },

  editPointImage(data) {
    return http.post("Schedule/editPointImage", data);
  },

  updateStrokePrice(data) {
    return http.post("Schedule/updateStrokePrice", data);
  },

  editAppraise(data) {
    return http.post("Schedule/editAppraise", data);
  },

  editCustomPrice(data) {
    return http.post("Schedule/editCustomPrice", data);
  },
};
