import http from "../support";
export default {
  async checkUser() {
    const result = await http.get("checkUser");
    return result;
  },

  async getUser() {
    const result = await http.get("getUser");
    return result;
  },
}
