import axios from "axios";
import api from "@/service/api/Member";

const state = {
  Login: {
    status: false,
    token: "",
    ckTime: "",
    uid: "",
    wUid: "",
    agency: "",
    name: "",
    showname: "",
    image: "",
    email: "",
    phone: "",
    sort: ""
  }
}

const getters = {}
const actions = {
  async checkUser({
    commit,
    state
  }, data) {
    const token = localStorage.getItem("Authorization");

    if (data || (token && !state.Login.status)) {
      commit("setToken", {
        token: data,
      });

      const getUser = await api.getUser();

      commit("setToken", {
        status: getUser.status,
      });

      commit("setUser", getUser.result);
    }

    const result = await api.checkUser();

    if (result.status) {
      commit("setCheckTime", result.result.cktime);
    }

    return result;
  },
}
const mutations = {
  setToken(state, data) {
    state.Login.status = data.status || false;

    if (data.token) {
      localStorage.setItem("Authorization", data.token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem(
        "Authorization");
      state.Login.token = localStorage.getItem("Authorization");
    }
  },

  setCheckTime(state, data) {
    if (state.Login.status) {
      state.Login.ckTime = data;
    }
  },

  setUser(state, data) {
    if (data.status) {
      const changeField = ["name", "showname", "wUid", "uid", "agency", "email", "image", "phone",
        "sort"
      ];

      _.each(changeField, (field) => {
        state.Login[field] = data[field];
      });
    }

  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
