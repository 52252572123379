import Vue from 'vue';
import Vuex from 'vuex';
// ----- store -----
import Map from './modules/Map';
import Member from './modules/Member'
import Public from './modules/Public';
import Search from './modules/search';
import Tour from './modules/Tour';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Map,
    Member,
    Public,
    Search,
    Tour
  },
});
